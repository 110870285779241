import axios from "@/api/baseRequest.js";

//增加课题章节
export const apiAddChapter = (formData) => axios.post("/api/1/admin/chapter/add", formData);

//修改课题章节
export const apiEditChapter = (formData) => axios.post("/api/1/admin/chapter/edit", formData);

//删除课题章节
export const apiDeleteChapter = (formData) => axios.post("/api/1/admin/chapter/delete", formData);
