import axios from "@/api/baseRequest.js";

//增加笔记
export const apiAddNote = (token, formData) =>
    axios.post("/api/1/admin/note/add", formData, {
        headers: {
            token: token
        }
    });

//修改笔记
export const apiEditNote = (token, formData) =>
    axios.post("/api/1/admin/note/edit", formData, {
        headers: {
            token: token
        }
    });

//删除笔记
export const apiDeleteNote = (formData) => axios.post("/api/1/admin/note/delete", formData);
