var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',{staticClass:"veadmin-iframe-content veadmin-iframe-content-custom-fixed"},[_c('div',{staticStyle:{"padding":"20px"}},[_c('el-button',{attrs:{"type":"primary","icon":"el-icon-plus","plain":""},on:{"click":function($event){return _vm.openLayer('新增课题', '/admin/topic/add', '600px', '150px')}}},[_vm._v("新增课题")]),_c('el-tabs',{staticStyle:{"margin-top":"10px"},attrs:{"type":"border-card"}},_vm._l((_vm.topicList),function(topic){return _c('el-tab-pane',{key:topic.id,attrs:{"label":topic.title}},[_c('div',{staticStyle:{"margin-bottom":"10px"}},[_c('i',{staticClass:"el-icon-edit icon-button",on:{"click":function($event){return _vm.openLayer(
                                    '修改课题：' + topic.title,
                                    '/admin/topic/edit?id=' + topic.id + '&title=' + topic.title,
                                    '600px',
                                    '150px'
                                )}}}),_c('i',{staticClass:"el-icon-delete icon-button",on:{"click":function($event){return _vm.confirmDeleteTopic(topic.title, topic.id)}}})]),_c('el-button',{attrs:{"plain":"","size":"small","icon":"el-icon-plus"},on:{"click":function($event){return _vm.openLayer('《' + topic.title + '》增加章节', '/admin/chapter/add?topicId=' + topic.id, '600px', '150px')}}},[_vm._v("增加章节")]),(topic.chapter_list.length > 0)?_c('ul',{staticStyle:{"padding-left":"20px"}},_vm._l((topic.chapter_list),function(chapter){return _c('li',[_vm._v(" "+_vm._s(chapter.title)+" "),_c('i',{staticClass:"el-icon-edit icon-button",staticStyle:{"margin-left":"10px"},on:{"click":function($event){return _vm.openLayer(
                                        '修改章节：' + chapter.title,
                                        '/admin/chapter/edit?id=' + chapter.id + '&title=' + chapter.title,
                                        '600px',
                                        '150px'
                                    )}}}),_c('i',{staticClass:"el-icon-delete icon-button",on:{"click":function($event){return _vm.confirmDeleteChapter(chapter.title, chapter.id)}}}),_c('div',[_c('el-button',{staticStyle:{"margin-top":"4px"},attrs:{"plain":"","size":"small","icon":"el-icon-plus"},on:{"click":function($event){return _vm.jumpTo(
                                            '/admin/note/addNote?chapterId=' +
                                                chapter.id +
                                                '&topicTitle=' +
                                                topic.title +
                                                '&chapterTitle=' +
                                                chapter.title
                                        )}}},[_vm._v("增加笔记")])],1),(chapter.note_list.length > 0)?_c('ul',{staticStyle:{"padding-left":"20px"}},_vm._l((chapter.note_list),function(note){return _c('li',[_vm._v(" "+_vm._s(note.title)+" "),_c('i',{staticClass:"el-icon-search icon-button",on:{"click":function($event){return _vm.openNewWindow('/note?topicId=' + topic.id + '&noteId=' + note.id)}}}),_c('i',{staticClass:"el-icon-edit icon-button",on:{"click":function($event){return _vm.jumpTo(
                                                '/admin/note/editNote?id=' +
                                                    note.id +
                                                    '&topicTitle=' +
                                                    topic.title +
                                                    '&chapterTitle=' +
                                                    chapter.title
                                            )}}}),_c('i',{staticClass:"el-icon-delete icon-button",on:{"click":function($event){return _vm.confirmDeleteNote(note.title, note.id)}}})])}),0):_vm._e()])}),0):_vm._e()],1)}),1)],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"veadmin-iframe-header veadmin-iframe-header-custom-fixed"},[_c('span',[_vm._v("当前位置：")]),_vm._v(" 资料管理 "),_c('span',[_vm._v(">")]),_vm._v(" 管理笔记 "),_c('button',{staticClass:"veadmin-iframe-button",attrs:{"onclick":"location.reload()"}},[_c('i',{staticClass:"el-icon-refresh-right"})])])
}]

export { render, staticRenderFns }