<style>
li {
    margin: 6px 0px;
}
.icon-button {
    color: #007eff;
    cursor: pointer;
    margin-left: 6px;
}
.icon-button:hover {
    color: #ff6a00;
}
</style>
<template>
    <div>
        <div class="veadmin-iframe-header veadmin-iframe-header-custom-fixed">
            <span>当前位置：</span>
            资料管理
            <span>&gt;</span>
            管理笔记
            <button class="veadmin-iframe-button" onclick="location.reload()">
                <i class="el-icon-refresh-right"></i>
            </button>
        </div>
        <div class="veadmin-iframe-content veadmin-iframe-content-custom-fixed">
            <div style="padding: 20px">
                <el-button type="primary" icon="el-icon-plus" plain @click="openLayer('新增课题', '/admin/topic/add', '600px', '150px')"
                    >新增课题</el-button
                >
                <el-tabs type="border-card" style="margin-top: 10px">
                    <el-tab-pane v-for="topic in topicList" :key="topic.id" :label="topic.title">
                        <div style="margin-bottom: 10px">
                            <i
                                class="el-icon-edit icon-button"
                                @click="
                                    openLayer(
                                        '修改课题：' + topic.title,
                                        '/admin/topic/edit?id=' + topic.id + '&title=' + topic.title,
                                        '600px',
                                        '150px'
                                    )
                                "
                            ></i>
                            <i class="el-icon-delete icon-button" @click="confirmDeleteTopic(topic.title, topic.id)"></i>
                        </div>
                        <el-button
                            plain
                            size="small"
                            icon="el-icon-plus"
                            @click="openLayer('《' + topic.title + '》增加章节', '/admin/chapter/add?topicId=' + topic.id, '600px', '150px')"
                            >增加章节</el-button
                        >
                        <ul v-if="topic.chapter_list.length > 0" style="padding-left: 20px">
                            <li v-for="chapter in topic.chapter_list">
                                {{ chapter.title }}
                                <i
                                    class="el-icon-edit icon-button"
                                    style="margin-left: 10px"
                                    @click="
                                        openLayer(
                                            '修改章节：' + chapter.title,
                                            '/admin/chapter/edit?id=' + chapter.id + '&title=' + chapter.title,
                                            '600px',
                                            '150px'
                                        )
                                    "
                                ></i>
                                <i class="el-icon-delete icon-button" @click="confirmDeleteChapter(chapter.title, chapter.id)"></i>
                                <div>
                                    <el-button
                                        style="margin-top: 4px"
                                        plain
                                        size="small"
                                        icon="el-icon-plus"
                                        @click="
                                            jumpTo(
                                                '/admin/note/addNote?chapterId=' +
                                                    chapter.id +
                                                    '&topicTitle=' +
                                                    topic.title +
                                                    '&chapterTitle=' +
                                                    chapter.title
                                            )
                                        "
                                        >增加笔记</el-button
                                    >
                                </div>
                                <ul v-if="chapter.note_list.length > 0" style="padding-left: 20px">
                                    <li v-for="note in chapter.note_list">
                                        {{ note.title }}
                                        <i
                                            class="el-icon-search icon-button"
                                            @click="openNewWindow('/note?topicId=' + topic.id + '&noteId=' + note.id)"
                                        ></i>
                                        <i
                                            class="el-icon-edit icon-button"
                                            @click="
                                                jumpTo(
                                                    '/admin/note/editNote?id=' +
                                                        note.id +
                                                        '&topicTitle=' +
                                                        topic.title +
                                                        '&chapterTitle=' +
                                                        chapter.title
                                                )
                                            "
                                        ></i>
                                        <i class="el-icon-delete icon-button" @click="confirmDeleteNote(note.title, note.id)"></i>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
    </div>
</template>

<script>
import prettyui from "@/assets/prettyui/prettyui.js";
import { apiListTopicAndChapterAndNote } from "@/api/public/topic.js";
import { apiDeleteTopic } from "@/api/admin/topic.js";
import { apiDeleteChapter } from "@/api/admin/chapter.js";
import { apiDeleteNote } from "@/api/admin/note.js";

export default {
    data() {
        return {
            topicList: []
        };
    },
    methods: {
        // 获取文章列表
        async listTopicAndChapterAndNote() {
            try {
                const response = await apiListTopicAndChapterAndNote();
                //console.log(response);
                if (response.data.code == 200) {
                    this.topicList = response.data.data;
                    // console.log(this.topicList);
                } else {
                    this.$message.error(response.data.message);
                }
            } catch (error) {
                console.log(error);
            }
        },
        confirmDeleteTopic(topicTitle, topicId) {
            this.$confirm("将删除课题【" + topicTitle + "】，是否继续?", "警告", {
                confirmButtonText: "继续",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(() => {
                    this.deleteTopic(topicId);
                })
                .catch(() => {
                    // 取消操作不需要提醒
                    // this.$message({type: "info",message: "已取消删除"});
                });
        },
        async deleteTopic(id) {
            let formData = new FormData();
            formData.append("token", this.$store.state.token);
            formData.append("id", id);

            //调用接口
            const response = await apiDeleteTopic(formData);
            if (response.data.code === 200) {
                this.$message({
                    message: "删除成功",
                    type: "success"
                });
                setTimeout(function () {
                    location.reload();
                }, 1000);
            } else {
                this.$message.error(response.data.message);
            }
        },
        confirmDeleteChapter(chapterTitle, chapterId) {
            this.$confirm("将删除章节【" + chapterTitle + "】，是否继续?", "警告", {
                confirmButtonText: "继续",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(() => {
                    this.deleteChapter(chapterId);
                })
                .catch(() => {
                    // 取消操作不需要提醒
                    // this.$message({type: "info",message: "已取消删除"});
                });
        },
        async deleteChapter(id) {
            let formData = new FormData();
            formData.append("token", this.$store.state.token);
            formData.append("id", id);

            //调用接口
            const response = await apiDeleteChapter(formData);
            if (response.data.code === 200) {
                this.$message({
                    message: "删除成功",
                    type: "success"
                });
                setTimeout(function () {
                    location.reload();
                }, 1000);
            } else {
                this.$message.error(response.data.message);
            }
        },
        confirmDeleteNote(noteTitle, noteId) {
            this.$confirm("将删除笔记【" + noteTitle + "】，是否继续?", "警告", {
                confirmButtonText: "继续",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(() => {
                    this.deleteNote(noteId);
                })
                .catch(() => {
                    // 取消操作不需要提醒
                    // this.$message({type: "info",message: "已取消删除"});
                });
        },
        async deleteNote(id) {
            let formData = new FormData();
            formData.append("token", this.$store.state.token);
            formData.append("id", id);

            //调用接口
            const response = await apiDeleteNote(formData);
            if (response.data.code === 200) {
                this.$message({
                    message: "删除成功",
                    type: "success"
                });
                setTimeout(function () {
                    location.reload();
                }, 1000);
            } else {
                this.$message.error(response.data.message);
            }
        },
        openLayer(title, url, width, height) {
            prettyui.layer({ title: title, url: url, width: width, height: height });
        },
        closeLayer() {
            //iframe关闭layer入口
            let nodes = document.getElementsByClassName("pui-layer-backdrop");
            for (let i = 0; i < nodes.length; i++) {
                document.body.removeChild(nodes[i]);
            }
        },
        listenerFun(e) {
            //子页面调用方法
            // console.log(e.data);
            this.closeLayer();
            if (e.data.code == "200") {
                this.$message({
                    message: e.data.message,
                    type: "success"
                });
                setTimeout(function () {
                    location.reload();
                }, 1000);
            }
        },
        jumpTo(url) {
            window.location.href = url;
        },
        openNewWindow(url) {
            window.open(url);
        }
    },
    mounted() {
        this.listTopicAndChapterAndNote();

        //监听iframe
        window.addEventListener("message", this.listenerFun);
    },
    beforeDestory() {
        // 页面销毁前，务必去除监听器，否则会造成资源泄露！
        window.removeEventListener("message", this.listenerFun);
    }
};
</script>
